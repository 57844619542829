import React, { useState, useEffect } from "react";
import axios from "axios";

const PolymarketData = () => {
  const [markets, setMarkets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "http://localhost:5000/api/polymarket-data"
        );
        setMarkets(response.data);
        setLoading(false);
      } catch (err) {
        setError(`Failed to fetch data: ${err.message}`);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div className="text-center py-4">Loading...</div>;
  if (error)
    return <div className="text-center py-4 text-red-500">Error: {error}</div>;

  return (
    <div className="polymarket-data">
      <h2 className="text-2xl font-bold mb-4">Polymarket Data</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {markets.map((market, index) => (
          <div key={index} className="bg-white shadow-md rounded-lg p-4">
            <h3 className="text-lg font-semibold mb-2">{market.title}</h3>
            <p className="text-gray-600">
              Price: ${parseFloat(market.price).toFixed(2)}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PolymarketData;
