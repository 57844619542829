// src/pages/Home.jsx
import React, { useEffect, useRef } from "react";

const Home = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://s3.tradingview.com/tv.js";
    script.async = true;
    script.onload = () => {
      if (window.TradingView && containerRef.current) {
        new window.TradingView.widget({
          width: "100%",
          height: 500,
          symbol: "NASDAQ:AAPL",
          interval: "D",
          timezone: "Etc/UTC",
          theme: "light",
          style: "1",
          locale: "en",
          toolbar_bg: "#f1f3f6",
          enable_publishing: false,
          allow_symbol_change: true,
          container_id: "tradingview_chart",
        });
      }
    };
    document.head.appendChild(script);

    return () => {
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, []);

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-3xl font-bold mb-4">Welcome to Webtek.online</h2>
      <p>
        Your one-stop solution for computers, networking, websites, and business
        solutions.
      </p>
      <div className="mt-8">
        <h3 className="text-2xl font-semibold mb-4">Market Overview</h3>
        <div id="tradingview_chart" ref={containerRef}></div>
      </div>
    </div>
  );
};

export default Home;
