import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import './index.css';
import Home from './Components/Home';
import Chart from './Components/Chart';
import Header from './Components/Header';
import Leverage from './Components/Leverage';
import Footer from './Components/Footer'; 
import YieldFarming from './Components/DefiLLama';
import StableCoin from './Components/StableCoin';
import PolymarketData from './Components/PolymarketData';

function App() {
  return (
    <Router>
      <div className="App flex flex-col min-h-screen"> 
        <Header />
        <main className="flex-grow"> 
          <Routes>
            <Route path="/" element={<Chart />} />
            <Route path="/Home" element={<Home />} />
            <Route path="/PolymarketData" element={<PolymarketData />} />
            <Route path="/StableCoin" element={<StableCoin />} />
            <Route path="/DefiLLama" element={<YieldFarming />} />
            <Route path="/Leverage" element={<Leverage />} />
          </Routes>
        </main>
        <Footer /> 
      </div>
    </Router>
  );
}

export default App;