import React, { useState, useEffect } from "react";
import axios from "axios";

function DefiLlama() {
  const [activeTab, setActiveTab] = useState("yield");
  const [yieldData, setYieldData] = useState([]);
  const [tvlData, setTvlData] = useState([]);
  const [stablecoinsData, setStablecoinsData] = useState([]);
  const [protocolsData, setProtocolsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      setError(null);
      try {
        const [
          yieldResponse,
          tvlResponse,
          stablecoinsResponse,
          protocolsResponse,
        ] = await Promise.all([
          axios.get("https://yields.llama.fi/pools"),
          axios.get("https://api.llama.fi/v2/chains"),
          axios.get("https://stablecoins.llama.fi/stablecoins"),
          axios.get("https://api.llama.fi/protocols"),
        ]);

        setYieldData(yieldResponse.data.data.slice(0, 10));
        setTvlData(tvlResponse.data.slice(0, 10));
        setStablecoinsData(stablecoinsResponse.data.peggedAssets.slice(0, 10));
        setProtocolsData(protocolsResponse.data.slice(0, 10));
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  if (loading) return <p className="text-center">Loading data...</p>;
  if (error) return <p className="text-red-500 text-center">Error: {error}</p>;

  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4 text-center">
        DeFi Llama Dashboard
      </h1>
      <div className="flex space-x-2 mb-4">
        <button
          onClick={() => setActiveTab("yield")}
          className={`flex-1 py-2 rounded ${
            activeTab === "yield" ? "bg-blue-500 text-white" : "bg-gray-200"
          }`}
        >
          Yield Farming
        </button>
        <button
          onClick={() => setActiveTab("tvl")}
          className={`flex-1 py-2 rounded ${
            activeTab === "tvl" ? "bg-blue-500 text-white" : "bg-gray-200"
          }`}
        >
          Total Value Locked
        </button>
        <button
          onClick={() => setActiveTab("stablecoins")}
          className={`flex-1 py-2 rounded ${
            activeTab === "stablecoins"
              ? "bg-blue-500 text-white"
              : "bg-gray-200"
          }`}
        >
          Stablecoins
        </button>
        <button
          onClick={() => setActiveTab("protocols")}
          className={`flex-1 py-2 rounded ${
            activeTab === "protocols" ? "bg-blue-500 text-white" : "bg-gray-200"
          }`}
        >
          Protocols
        </button>
      </div>
      <div className="tab-content">
        {activeTab === "yield" && (
          <div>
            <h2 className="text-xl font-semibold mb-2">
              Top 10 Yield Farming Opportunities
            </h2>
            <table className="min-w-full bg-white border border-gray-300">
              <thead>
                <tr className="bg-gray-200">
                  <th className="py-2 px-4 border">Protocol</th>
                  <th className="py-2 px-4 border">Pool</th>
                  <th className="py-2 px-4 border">APY</th>
                  <th className="py-2 px-4 border">TVL</th>
                </tr>
              </thead>
              <tbody>
                {yieldData.map((item, index) => (
                  <tr key={index} className="hover:bg-gray-100">
                    <td className="py-2 px-4 border">{item.project}</td>
                    <td className="py-2 px-4 border">{item.symbol}</td>
                    <td className="py-2 px-4 border">{item.apy.toFixed(2)}%</td>
                    <td className="py-2 px-4 border">
                      ${item.tvlUsd.toLocaleString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {activeTab === "tvl" && (
          <div>
            <h2 className="text-xl font-semibold mb-2">
              Top 10 Chains by Total Value Locked
            </h2>
            <table className="min-w-full bg-white border border-gray-300">
              <thead>
                <tr className="bg-gray-200">
                  <th className="py-2 px-4 border">Chain</th>
                  <th className="py-2 px-4 border">TVL</th>
                </tr>
              </thead>
              <tbody>
                {tvlData.map((chain) => (
                  <tr key={chain.name} className="hover:bg-gray-100">
                    <td className="py-2 px-4 border">{chain.name}</td>
                    <td className="py-2 px-4 border">
                      ${chain.tvl.toLocaleString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {activeTab === "stablecoins" && (
          <div>
            <h2 className="text-xl font-semibold mb-2">Top 10 Stablecoins</h2>
            <table className="min-w-full bg-white border border-gray-300">
              <thead>
                <tr className="bg-gray-200">
                  <th className="py-2 px-4 border">Name</th>
                  <th className="py-2 px-4 border">Symbol</th>
                  <th className="py-2 px-4 border">Circulating</th>
                </tr>
              </thead>
              <tbody>
                {stablecoinsData.map((stablecoin) => (
                  <tr key={stablecoin.id} className="hover:bg-gray-100">
                    <td className="py-2 px-4 border">{stablecoin.name}</td>
                    <td className="py-2 px-4 border">{stablecoin.symbol}</td>
                    <td className="py-2 px-4 border">
                      ${stablecoin.circulating.toLocaleString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {activeTab === "protocols" && (
          <div>
            <h2 className="text-xl font-semibold mb-2">
              Top 10 Protocols by TVL
            </h2>
            <table className="min-w-full bg-white border border-gray-300">
              <thead>
                <tr className="bg-gray-200">
                  <th className="py-2 px-4 border">Name</th>
                  <th className="py-2 px-4 border">TVL</th>
                  <th className="py-2 px-4 border">Chain</th>
                </tr>
              </thead>
              <tbody>
                {protocolsData.map((protocol) => (
                  <tr key={protocol.id} className="hover:bg-gray-100">
                    <td className="py-2 px-4 border">{protocol.name}</td>
                    <td className="py-2 px-4 border">
                      ${protocol.tvl.toLocaleString()}
                    </td>
                    <td className="py-2 px-4 border">{protocol.chain}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}

export default DefiLlama;
