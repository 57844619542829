import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const Header = () => {
  const [prices, setPrices] = useState({});

  const coins = useMemo(
    () => [
      { id: "bitcoin", symbol: "BTC", name: "Bitcoin" },
      { id: "ethereum", symbol: "ETH", name: "Ethereum" },
      { id: "binancecoin", symbol: "BNB", name: "Binance Coin" },
      { id: "ripple", symbol: "XRP", name: "Ripple" },
      { id: "solana", symbol: "SOL", name: "Solana" },
    ],
    []
  );

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const response = await axios.get(
          `https://api.coingecko.com/api/v3/simple/price?ids=${coins
            .map((coin) => coin.id)
            .join(",")}&vs_currencies=usd&include_24hr_change=true`
        );
        setPrices(response.data);
      } catch (error) {
        console.error("Error fetching prices:", error);
      }
    };

    fetchPrices();
    const interval = setInterval(fetchPrices, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [coins]);

  return (
    <header className="bg-orange-400 shadow-md">
      <div className="container mx-auto px-4 py-4">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-white text-2xl font-bold">HODL IT</h1>
          <nav>
            <ul className="flex space-x-6">
              <li>
                <Link
                  to="/"
                  className="text-white hover:text-orange-200 transition duration-300"
                >
                  Home
                </Link>
              </li>

              <li>
                <Link
                  to="/DefiLLama"
                  className="text-white hover:text-orange-200 transition duration-300"
                >
                  DefiLLama
                </Link>
              </li>
              <li>
                <Link
                  to="/Leverage"
                  className="text-white hover:text-orange-200 transition duration-300"
                >
                  Leverage
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className="overflow-hidden mb-4">
          <div className="flex animate-ticker">
            {[...coins, ...coins].map((coin, index) => (
              <div
                key={`${coin.id}-${index}`}
                className="flex-shrink-0 flex items-center justify-center bg-gray-800 rounded-lg p-2 mx-2 w-48"
              >
                <div className="flex flex-col items-center">
                  <span className="text-sm font-semibold text-gray-400">
                    {coin.name}
                  </span>
                  <span className="text-lg font-bold text-white">
                    ${prices[coin.id]?.usd.toFixed(2) || "-.--"}
                  </span>
                  {prices[coin.id]?.usd_24h_change && (
                    <span
                      className={`text-xs ${
                        prices[coin.id].usd_24h_change > 0
                          ? "text-green-500"
                          : "text-red-500"
                      }`}
                    >
                      {prices[coin.id].usd_24h_change.toFixed(2)}%
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
