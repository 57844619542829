import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Line, Bar } from "react-chartjs-2";
import { Tooltip } from "react-tooltip";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip as ChartTooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  ChartTooltip,
  Legend
);

const usageCategories = ["Trading", "DeFi", "Payments", "Savings", "Other"];

const StableCoin = () => {
  const stablecoins = useMemo(
    () => [
      { id: "tether", symbol: "USDT", name: "Tether" },
      { id: "usd-coin", symbol: "USDC", name: "USD Coin" },
      { id: "binance-usd", symbol: "BUSD", name: "Binance USD" },
    ],
    []
  );

  const [stablecoinPrinting, setStablecoinPrinting] = useState({});
  const [historicalData, setHistoricalData] = useState([]);
  const [historicalUsageData, setHistoricalUsageData] = useState([]);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: stablecoins.map((coin) => ({
      label: coin.name,
      data: [],
      borderColor: getRandomColor(),
      tension: 0.1,
    })),
  });
  const [timeRange, setTimeRange] = useState("daily");
  const [usageTimeRange, setUsageTimeRange] = useState("daily");

  useEffect(() => {
    const generateHistoricalData = () => {
      const data = [];
      const usageData = [];
      const endDate = new Date();
      const startDate = new Date(
        endDate.getFullYear() - 1,
        endDate.getMonth(),
        endDate.getDate()
      );

      for (
        let d = new Date(startDate);
        d <= endDate;
        d.setDate(d.getDate() + 1)
      ) {
        const dailyData = stablecoins.reduce((acc, coin) => {
          acc[coin.id] = {
            amount: Math.floor(Math.random() * 1000000) + 500000,
            date: d.toISOString().split("T")[0],
          };
          return acc;
        }, {});
        data.push({ date: d.toISOString().split("T")[0], ...dailyData });

        const dailyUsageData = {
          date: d.toISOString().split("T")[0],
          usage: stablecoins.map((coin) => ({
            name: coin.name,
            data: usageCategories.map((category) => ({
              category,
              percentage: Math.random() * 100,
            })),
          })),
        };
        usageData.push(dailyUsageData);
      }

      setHistoricalData(data);
      setHistoricalUsageData(usageData);
      setStablecoinPrinting(data[data.length - 1]);
    };

    generateHistoricalData();
  }, [stablecoins]);

  const updateChartData = useCallback(() => {
    const filteredData = filterDataByTimeRange(historicalData, timeRange);
    setChartData({
      labels: filteredData.map((data) => data.date),
      datasets: stablecoins.map((coin, index) => ({
        label: coin.name,
        data: filteredData.map((data) => data[coin.id]?.amount || 0),
        borderColor: chartData.datasets[index]?.borderColor || getRandomColor(),
        tension: 0.1,
      })),
    });
  }, [historicalData, timeRange, stablecoins, chartData.datasets]);

  useEffect(() => {
    updateChartData();
  }, [historicalData, timeRange, updateChartData]);

  const filterDataByTimeRange = (data, range) => {
    const now = new Date();
    const oneDay = 24 * 60 * 60 * 1000;
    return data.filter((item) => {
      const itemDate = new Date(item.date);
      switch (range) {
        case "daily":
          return now - itemDate <= oneDay;
        case "monthly":
          return now - itemDate <= 30 * oneDay;
        case "yearly":
          return now - itemDate <= 365 * oneDay;
        default:
          return true;
      }
    });
  };

  function getRandomColor() {
    return `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(
      Math.random() * 256
    )}, ${Math.floor(Math.random() * 256)})`;
  }

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Stablecoin Printing Over Time",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Date",
        },
      },
      y: {
        title: {
          display: true,
          text: "Amount Printed",
        },
      },
    },
  };

  const UsageChart = ({ data, timeRange }) => {
    const filteredData = filterDataByTimeRange(data, timeRange);
    const latestUsageData = filteredData[filteredData.length - 1]?.usage || [];

    const chartData = {
      labels: usageCategories,
      datasets: latestUsageData.map((coin, index) => ({
        label: coin.name,
        data: coin.data.map((d) => d.percentage),
        backgroundColor: getRandomColor(),
      })),
    };

    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: `Stablecoin Usage Distribution (${timeRange})`,
        },
      },
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
          title: {
            display: true,
            text: "Percentage",
          },
        },
      },
    };

    return <Bar data={chartData} options={options} />;
  };

  return (
    <div className="container mx-auto">
      <h2
        className="text-4xl font-extrabold text-center uppercase tracking-wide text-white shadow-text-dark bg-orange-400 mb-5"
        data-tooltip-id="stablecoin-printing-tooltip"
        data-tooltip-html="
    <div style='max-width: 300px; text-align: left;'>
      <p><strong>Stablecoin Printing:</strong></p>
      <ul style='padding-left: 20px; list-style-type: disc;'>
        <li>Process of creating new stablecoins</li>
        <li>Introduces new coins into circulation</li>
        <li>Stablecoins maintain stable value</li>
        <li>Usually pegged to fiat (e.g., USD) or commodities (e.g., gold)</li>
      </ul>
    </div>
  "
      >
        Stablecoin Printing
      </h2>
      <Tooltip id="stablecoin-printing-tooltip" />
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
        {stablecoins.map((coin) => (
          <div key={coin.id} className="bg-gray-700 rounded-lg p-2">
            <span className="text-sm font-semibold text-gray-400">
              {coin.name}
            </span>
            <div className="text-lg font-bold text-white">
              ${stablecoinPrinting[coin.id]?.amount.toLocaleString() || "-.--"}
            </div>
            <div className="text-xs text-gray-400">
              Last updated: {stablecoinPrinting[coin.id]?.date || "N/A"}
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-center space-x-4 mb-4">
        <button
          className={`px-4 py-2 rounded ${
            timeRange === "daily"
              ? "bg-blue-500 text-white"
              : "bg-gray-300 text-black"
          }`}
          onClick={() => setTimeRange("daily")}
        >
          Daily
        </button>
        <button
          className={`px-4 py-2 rounded ${
            timeRange === "monthly"
              ? "bg-blue-500 text-white"
              : "bg-gray-300 text-black"
          }`}
          onClick={() => setTimeRange("monthly")}
        >
          Monthly
        </button>
        <button
          className={`px-4 py-2 rounded ${
            timeRange === "yearly"
              ? "bg-blue-500 text-white"
              : "bg-gray-300 text-black"
          }`}
          onClick={() => setTimeRange("yearly")}
        >
          Yearly
        </button>
      </div>
      <div className="bg-white rounded-lg p-4">
        <Line options={chartOptions} data={chartData} />
      </div>
      <div className="bg-white rounded-lg p-4 mt-4">
        <h2 className="text-xl font-bold mb-2">Stablecoin Usage</h2>
        <div className="flex justify-center space-x-4 mb-4">
          <button
            className={`px-4 py-2 rounded ${
              usageTimeRange === "daily"
                ? "bg-blue-500 text-white"
                : "bg-gray-300 text-black"
            }`}
            onClick={() => setUsageTimeRange("daily")}
          >
            Daily
          </button>
          <button
            className={`px-4 py-2 rounded ${
              usageTimeRange === "monthly"
                ? "bg-blue-500 text-white"
                : "bg-gray-300 text-black"
            }`}
            onClick={() => setUsageTimeRange("monthly")}
          >
            Monthly
          </button>
          <button
            className={`px-4 py-2 rounded ${
              usageTimeRange === "yearly"
                ? "bg-blue-500 text-white"
                : "bg-gray-300 text-black"
            }`}
            onClick={() => setUsageTimeRange("yearly")}
          >
            Yearly
          </button>
        </div>
        <UsageChart data={historicalUsageData} timeRange={usageTimeRange} />
      </div>
    </div>
  );
};

export default StableCoin;
