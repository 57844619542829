import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import moment from "moment";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function Chart() {
  const [currentIndex, setCurrentIndex] = useState(null);
  const [historicalData, setHistoricalData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [timeframe, setTimeframe] = useState(30);

  const fetchCurrentIndex = useCallback(async () => {
    try {
      const response = await axios.get("https://api.alternative.me/fng/");
      setCurrentIndex(response.data.data[0]);
      setError(null);
    } catch (err) {
      setError("Error fetching current data");
    }
  }, []);

  const fetchHistoricalData = useCallback(async () => {
    setLoading(true);
    try {
      let allData = [];
      const limit = 100; // API limit per request
      const iterations = Math.ceil(timeframe / limit);

      for (let i = 0; i < iterations; i++) {
        const offset = i * limit;
        const daysToFetch = Math.min(limit, timeframe - offset);
        const response = await axios.get(
          `https://api.alternative.me/fng/?limit=${daysToFetch}&offset=${offset}`
        );
        allData = [...allData, ...response.data.data];
      }

      setHistoricalData(allData.reverse());
      setError(null);
    } catch (err) {
      setError("Error fetching historical data");
    } finally {
      setLoading(false);
    }
  }, [timeframe]);

  useEffect(() => {
    fetchCurrentIndex();
    fetchHistoricalData();

    const interval = setInterval(fetchCurrentIndex, 60000);

    return () => clearInterval(interval);
  }, [timeframe, fetchHistoricalData, fetchCurrentIndex]);

  const handleTimeframeChange = (days) => {
    setTimeframe(days);
  };

  const getColorClass = (value) => {
    if (value <= 25) return "bg-red-600";
    if (value <= 45) return "bg-orange-500";
    if (value <= 55) return "bg-yellow-500";
    if (value <= 75) return "bg-green-500";
    return "bg-green-700";
  };

  const chartData = {
    labels: historicalData.map((data) =>
      moment.unix(data.timestamp).format("MM/DD/YYYY")
    ),
    datasets: [
      {
        label: "Fear & Greed Index",
        data: historicalData.map((data) => data.value),
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Fear & Greed Index Over Time",
      },
    },
  };

  return (
    <div className="container mx-auto">
      <h1 className="text-4xl font-extrabold text-center uppercase tracking-wide text-white shadow-text-dark bg-orange-400">
        Crypto Fear & Greed
      </h1>

      {loading && <p className="text-center text-gray-600">Loading...</p>}
      {error && <p className="text-center text-red-500">{error}</p>}

      {currentIndex && (
        <div
          className={`index-container ${getColorClass(
            currentIndex.value
          )} p-6 rounded-lg shadow-lg mb-8 text-white`}
        >
          <h2 className="text-3xl font-bold mb-4">
            Current Index: {currentIndex.value}
          </h2>
          <p className="text-xl mb-2">
            Classification: {currentIndex.value_classification}
          </p>
          <p className="text-sm">
            Last Updated:{" "}
            {moment
              .unix(currentIndex.timestamp)
              .format("MMMM Do YYYY, h:mm:ss a")}
          </p>
        </div>
      )}

      <div className="timeframe-buttons flex justify-center space-x-2 mb-6">
        <button
          onClick={() => handleTimeframeChange(7)}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:scale-105"
        >
          7 Days
        </button>
        <button
          onClick={() => handleTimeframeChange(30)}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:scale-105"
        >
          30 Days
        </button>
        <button
          onClick={() => handleTimeframeChange(90)}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:scale-105"
        >
          90 Days
        </button>
        <button
          onClick={() => handleTimeframeChange(180)}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:scale-105"
        >
          180 Days
        </button>
        <button
          onClick={() => handleTimeframeChange(365)}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:scale-105"
        >
          1 Year
        </button>
      </div>

      {historicalData.length > 0 && (
        <div className="bg-white p-4 rounded-lg shadow-lg">
          <Line data={chartData} options={chartOptions} />
        </div>
      )}
    </div>
  );
}

export default Chart;
