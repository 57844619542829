import React, { useState, useEffect, useMemo } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const LeverageInfoComponent = () => {
  const [leverageData, setLeverageData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLeverageData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(
          `https://api.coingecko.com/api/v3/derivatives?order=market_cap_desc&asset_id=bitcoin`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const jsonData = await response.json();
        setLeverageData(jsonData);
      } catch (error) {
        console.error("Error fetching leverage data:", error);
        setError("Failed to fetch leverage data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchLeverageData();
  }, []);

  const chartData = useMemo(() => {
    if (leverageData.length === 0) return null;

    const topMarkets = leverageData.slice(0, 5);
    const labels = topMarkets.map((market) => market.symbol);
    const openInterestData = topMarkets.map((market) => market.open_interest);

    return {
      labels,
      datasets: [
        {
          label: "Open Interest",
          data: openInterestData,
          backgroundColor: "rgba(53, 162, 235, 0.5)",
          borderColor: "rgba(53, 162, 235, 1)",
          borderWidth: 1,
        },
      ],
    };
  }, [leverageData]);

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Open Interest for Top 5 Bitcoin Derivatives",
        font: {
          size: 16,
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(context.parsed.y);
            }
            return label;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Open Interest (USD)",
        },
        ticks: {
          callback: function (value, index, values) {
            return new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              notation: "compact",
            }).format(value);
          },
        },
      },
    },
  };

  if (loading)
    return <div className="text-center py-4">Loading leverage data...</div>;
  if (error)
    return <div className="text-red-500 text-center py-4">{error}</div>;

  return (
    <div className="max-w-4xl mx-auto">
      <h1 className="text-4xl font-extrabold text-center uppercase tracking-wide text-white shadow-text-dark bg-orange-400">
        Bitcoin Derivatives
      </h1>

      {chartData && (
        <div className="bg-white shadow-md rounded-lg p-6 mb-6">
          <Bar options={chartOptions} data={chartData} />
        </div>
      )}
    </div>
  );
};

export default LeverageInfoComponent;
